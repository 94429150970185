import { format } from "date-fns";
import { get, shuffle } from "lodash";
import { graphql, StaticQuery } from "gatsby";
import React, { Fragment, Component } from "react";
import styled from "styled-components";
import { A, H2, H3, H4, P } from "@upsolve/ui";
import ImageInContext from "../Media/ImageInContext";

class TestimonialsWall extends Component {
  constructor(props) {
    super(props);
    const { show = ["wall", "story"] } = props;
    this.state = {
      focusedIndex: show.includes("story") ? Math.floor(Math.random() * 19) : null,
    };
  }

  changeFocus(index) {
    this.setState({ focusedIndex: index });
    const { show = ["wall", "story"] } = this.props;
    if (show.includes("story")) {
      scrollTo({
        top: document.querySelector(".testimonial-story__bio").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query TestimonialsWallQuery {
            allContentfulReview(filter: { image: { id: { ne: null } } }, sort: { fields: [date], order: ASC }) {
              edges {
                node {
                  reviewer
                  featured
                  date
                  review {
                    review
                  }
                  causeForDebt {
                    causeForDebt
                  }
                  adviceForOthers {
                    adviceForOthers
                  }
                  afterBankruptcy {
                    afterBankruptcy
                  }
                  image {
                    altText
                    image {
                      fluid(maxWidth: 120) {
                        ...GatsbyContentfulFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const { show = ["wall", "story"] } = this.props;
          const { focusedIndex } = this.state;
          const focusedReview = get(data.allContentfulReview.edges[focusedIndex], "node", {});
          const reviews = data.allContentfulReview.edges.map((e) => e.node);

          return (
            <Fragment>
              <TestimonialsWallWrapper>
                {Array.prototype.map.call(reviews.slice(0, 76), (review, index) => {
                  return (
                    <div
                      key={index}
                      className={index === focusedIndex ? "focus" : ""}
                      onClick={() => this.changeFocus(index)}
                    >
                      <ImageInContext alt={review.image.altText} fluid={review.image.image.fluid} />
                    </div>
                  );
                })}
                <div className="testimonial-wall__headline">
                  <H2 as="h1">Everyone Deserves a Second Chance</H2>
                  {show.includes("story") ? (
                    <P>Click a photo to learn about life before and after using Upsolve.</P>
                  ) : (
                    <P>
                      <u>
                        <A color="white" href="/fresh-starts/">
                          Read real stories of people who filed bankruptcy with Upsolve →
                        </A>
                      </u>
                    </P>
                  )}
                </div>
              </TestimonialsWallWrapper>
              {show.includes("story") && (
                <TestimonialStory>
                  <div className="testimonial-story__bio">
                    <ImageInContext alt={focusedReview.image.altText} fluid={focusedReview.image.image.fluid} />
                    <H3 as="h2">{focusedReview.reviewer}</H3>
                    <P>Finished {format(new Date(focusedReview.date), "MMMM, d yyyy")}</P>
                  </div>
                  <div className="testimonial-story__answers">
                    {get(focusedReview, "causeForDebt.causeForDebt") && (
                      <section>
                        <H4>How did you fall into debt?</H4>
                        <P>{get(focusedReview, "causeForDebt.causeForDebt")}</P>
                      </section>
                    )}
                    {get(focusedReview, "adviceForOthers.adviceForOthers") && (
                      <section>
                        <H4>What advice do you have for people considering bankruptcy?</H4>
                        <P>{get(focusedReview, "adviceForOthers.adviceForOthers")}</P>
                      </section>
                    )}
                    {get(focusedReview, "review.review") && (
                      <section>
                        <H4>What did you like most about using Upsolve? </H4>
                        <P>{get(focusedReview, "review.review")}</P>
                      </section>
                    )}
                    {get(focusedReview, "afterBankruptcy.afterBankruptcy") && (
                      <section>
                        <H4>How has bankruptcy changed your life?</H4>
                        <P>{get(focusedReview, "afterBankruptcy.afterBankruptcy")}</P>
                      </section>
                    )}
                  </div>
                </TestimonialStory>
              )}
            </Fragment>
          );
        }}
      />
    );
  }
}

const TestimonialStory = styled.div`
  display: flex;
  max-width: 800px;
  margin: 1.5em auto;
  img {
    height: 180px;
    width: 180px;
    border-radius: 120px;
  }
  & > div {
    padding: 1em 1.5em;
    section {
      margin-bottom: 2.5em;
    }
    &.testimonial-story__bio {
      width: 240px;
      min-width: 240px;
      text-align: center;
      h2 {
        color: ${({ theme }) => theme.colors.brand["500"]};
      }
      p {
        margin-top: 0.5em;
        color: ${({ theme }) => theme.colors.gray["900"]};
      }
    }
    &.testimonial-story__answers {
      width: 100%;
      h4 {
        color: ${({ theme }) => theme.colors.gray["700"]};
      }
    }
  }

  @media (max-width: 75em) {
    flex-direction: column;
    align-items: center;
  }
`;

const TestimonialsWallWrapper = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(5, 1fr);
  overflow: hidden;
  & > div {
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: flex;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.brand["200"]};
    & > div {
      width: 100%;
      height: 100%;
      opacity: 0.35;
    }
    &.focus {
      & > div {
        opacity: 1;
      }
      picture,
      img {
        mix-blend-mode: unset;
      }
    }
  }

  div.testimonial-wall__headline {
    grid-column-start: 3;
    grid-column-end: 11;
    grid-row-start: 3;
    grid-row-end: 4;
    background: ${({ theme }) => theme.colors.brand["500"]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1,
    h2 {
      color: white;
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-top: 0.2em;
    }
    p {
      color: white;
      margin-top: 0;
      opacity: 0.8;
    }
  }

  @media (max-width: 45em) {
    border-radius: 0;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr) 180px repeat(1, 1fr);
    justify-content: center;
    div.testimonial-wall__headline {
      grid-column-start: 1;
      grid-column-end: 11;
    }
  }
`;

export default TestimonialsWall;
