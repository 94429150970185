import React, { Component } from "react";
import styled from "styled-components";
import { HR } from "@upsolve/ui";
import { get } from "lodash";
import { graphql } from "gatsby";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import CTASideBySideOfferings from "../components/CTA/CTASideBySideOfferings";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import Page from "../components/Layout/Page.div";
import SEO from "../components/SEO/SEO";
import TestimonialsWall from "../components/Programmatic/TestimonialsWall";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import { CTATarget } from "../components/CTA/getCTAData";

const contentGroup = CONTENT_GROUPS.FRESH_START_DIARY;
const templateProps = {
  pageComponentName: "FreshStarts",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

class FreshStarts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tag: null,
    };
  }

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  changeTag(tag = "") {
    this.setState({ tag: tag.length === 0 ? null : tag });
  }

  render() {
    const stories = get(this.props, "data.stories.edges", []).map((s) => s.node);
    const cta = "Get Your Fresh Start";

    return (
      <TopLevelLayout persistentCta={cta}>
        <div className="index-container">
          <SEO
            title="Fresh Start Diaries | Upsolve"
            description="Read the stories of Americans who started new chapters to their lives by filing for bankruptcy."
            image="https://upsolve.org/images/meta-probono.png"
            url="https://upsolve.org/fresh-starts/"
            jsonLD={[
              {
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Fresh Starts",
                    item: "https://upsolve.org/fresh-starts/",
                  },
                ],
              },
            ]}
          />
          <MainHeader trackingPageContext={trackingPageContext} ctaTarget={CTATarget.DEBT_TRIAGE} />
          <main>
            <FreshStartPage>
              <TestimonialsWall />
            </FreshStartPage>
          </main>
          <HR />
          <CTASideBySideOfferings trackingPageContext={trackingPageContext} ctaTarget={CTATarget.DEBT_TRIAGE} />
          <MainFooter />
        </div>
      </TopLevelLayout>
    );
  }
}

const FreshStartPage = styled(Page)`
  padding: 1em;
  width: 100%;
  min-width: 100%;
  margin-left: auto;
  margin-right: auto;
  .fresh-start__header {
    text-align: center;
    margin-top: 2em;
  }
  .fresh-start__testimonials {
  }
  .fresh-start__listing {
    max-width: 920px;
    margin: 0 auto;
    .fresh-start__listing__select {
      width: 100%;
      display: flex;
      justify-content: center;
      select {
        max-width: 480px;
        @media (max-width: 45em) {
          width: 100%;
        }
      }
    }
    .fresh-start__listing__stories {
      display: flex;
      flex-wrap: wrap;
      padding: 1em 0;
      justify-content: center;
      text-align: center;
      & > div {
        flex: 1 1 240px;
      }
    }
  }
`;

export default FreshStarts;

/* eslint no-undef: "off"*/
export const pageQuery = graphql`
  query FreshStartDirectoryQuery {
    stories: allContentfulFreshStartDiary(limit: 1000, sort: { fields: [caseFiled], order: DESC }) {
      edges {
        node {
          id
          publishedAt
          slug
          title
          filerName
          filerImage {
            altText
            image {
              file {
                url
              }
            }
          }
          storyTags
          storyQuote {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          caseFiled
          seoTitle
          seoDescription
          seoImage {
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;
